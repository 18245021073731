<template>
  <v-col class="regads mt-2">
    <v-col class="shades white" :class="$vuetify.breakpoint.mdAndUp ? 'pa-10' : 'pa-6'">
      <v-row class="mb-5">
        <v-col cols="12" md="12">
          <div class="header">
            블로거의 매장 방문 시, <span class="font-weight-bold text-decoration-underline">예약</span>을 받을까요?
          </div>
        </v-col>
      </v-row>
      <v-row class="mb-6" style="gap: 8px">
        <v-col @click="selectReservation(0)">
          <div class="radio-box" :class="{ selected: form.isReservationRequired === 0 }">
            <div class="radio-box-header mb-5">
              <v-icon size="20">mdi-radiobox-marked</v-icon>
              <div>예약 없이 방문해도 괜찮아요</div>
            </div>
            <div class="radio-box-content">
              <div>• 블로거 모집이 더 빨라요.</div>
              <div>• 상위 블로거 모집에 적합해요.</div>
              <div>• 예약 조율, 노쇼 등 번거로운 관리가 필요치 않아요.</div>
              <div>• 대기가 우려된다면, 안내를 추가해 드려요.</div>
              <div style="margin-left: 12px; font-size: 12px">
                예) 예약 없이 방문 가능하나 대기가 발생할 수 있습니다.
              </div>
            </div>
          </div>
        </v-col>
        <v-col @click="selectReservation(1)">
          <div class="radio-box" :class="{ selected: form.isReservationRequired === 1 }">
            <div class="radio-box-header mb-5">
              <v-icon size="20">mdi-radiobox-marked</v-icon>
              <div>반드시 예약을 받을래요.</div>
            </div>
            <div class="radio-box-content">
              <div>• 규모가 작은 매장에 적합해요</div>
              <div>• 잦은 방문이 부담스러운 사장님께 적합해요.</div>
            </div>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col v-if="form.isReservationRequired" cols="12" md="12">
          <div class="info-header mb-3">예약 안내</div>
          <v-text-field
            v-model="form.reservationComment"
            @change="checkDone"
            name="reservationComment"
            type="text"
            placeholder="예) 전화 또는 문자 예약 필수 (010-0000-0000)"
            single-line
            outlined
            solo
            flat></v-text-field>
        </v-col>
      </v-row>
    </v-col>
  </v-col>
</template>

<script>
export default {
  name: 'Page3',
  components: {},
  props: ['adsData', 'option'],
  data: function () {
    return {
      form: {
        isReservationRequired: this.adsData.isReservationRequired || 0,
        reservationComment: this.adsData.reservationComment,
      },
    };
  },
  methods: {
    selectReservation(isReservationRequired) {
      if (!isReservationRequired) {
        this.form.reservationComment = null;
      } else {
        this.form.reservationComment = '';
      }
      this.form.isReservationRequired = isReservationRequired;
      this.checkDone();
    },
    checkDone() {
      this.form.step = 3;
      this.$emit('updateEvent', this.form);
    },
  },
};
</script>

<style scoped lang="scss">
.header {
  font-size: 18px;
  font-weight: 400;
  color: #000;
}

.info-header {
  font-size: 14px;
  font-weight: 700;
  color: #000;
}

.radio-box {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 185px;
  min-width: 316px;
  padding: 12px;
  border-radius: 8px;
  border: 1px solid var(--disabled-color);
  cursor: pointer;
}

.radio-box-header {
  display: flex;
  align-items: flex-start;
  font-size: 14px;
  line-height: 20px;
  font-weight: 700;
  gap: 8px;
  color: #000;
}

.radio-box-content {
  display: flex;
  flex-direction: column;
  font-size: 13px;
  color: var(--default-light-color);
  gap: 4px;
}

.selected {
  background-color: rgba(234, 42, 58, 0.05);

  i {
    color: var(--primary-light-color);
  }
  div {
    color: #000;
  }
}
</style>
